import React from 'react';
// import { Link } from 'gatsby';
import { graphql, useStaticQuery } from 'gatsby';
import propTypes from 'prop-types';
// import styled from 'styled-components';
// import { graphql } from 'gatsby';
// import Img from 'gatsby-image';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import SEO from '../components/seo';

import termitesStyles from './termites.module.scss';

const TermitesPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulTermites(sort: { fields: order, order: ASC }) {
        edges {
          node {
            order
            slug
            title
            description {
              childMarkdownRemark {
                html
              }
            }
            image {
              fluid {
                ...GatsbyContentfulFluid_noBase64
              }
            }
          }
        }
      }
    }
  `);
  const species = data.allContentfulTermites.edges.map(node => {
    return (
      <div className={termitesStyles.species} id={node.node.slug}>
        <div className={termitesStyles.speciesTitle}>
          <a href={`#${node.node.slug}`}>{node.node.title}</a>
        </div>
        <div className={termitesStyles.speciesContent}>
          <div className="columns is-centered">
            <div className="column has-text-centered">
              <div className={termitesStyles.speciesImage}>
                <Img
                  style={{ height: '12rem', width: '12rem' }}
                  fluid={node.node.image.fluid}
                />
              </div>
            </div>
            <div className="column is-four-fifths">
              <div className={termitesStyles.speciesText}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: node.node.description.childMarkdownRemark.html,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });
  return (
    <Layout>
      <SEO
        title="Termites"
        pathname={location.pathname}
        description="Tycecc Termite Control. Los Angeles, California Termite and Pest Control Service.
      Schedule today for a free estimate. Established since 1999. Treated over 4k homes and businesses.
      We offer treatments: fumigation, local treatment, green eco-friendly alternatives."
      />
      <div className={termitesStyles.container}>{species}</div>
    </Layout>
  );
};

export default TermitesPage;

TermitesPage.propTypes = {
  location: propTypes.shape({
    pathname: propTypes.string,
  }),
};

TermitesPage.defaultProps = {
  location: ``,
};
